import "./src/styles/modern-normalize.css"
import "./src/styles/global.css"
import "./src/styles/top.css"
import React from "react"

function resizeFullHeight() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  document.documentElement.style.setProperty(
    "--backgroundColor",
    process.env.GATSBY_BACKGROUND_COLOR
  )
}

resizeFullHeight()
window.addEventListener("resize", () => {
  resizeFullHeight()
})
