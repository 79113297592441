// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[address]-token-id-tsx": () => import("./../../../src/pages/[address]/[tokenId].tsx" /* webpackChunkName: "component---src-pages-[address]-token-id-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-buy-eth-tsx": () => import("./../../../src/pages/buy_eth.tsx" /* webpackChunkName: "component---src-pages-buy-eth-tsx" */),
  "component---src-pages-collection-[id]-tsx": () => import("./../../../src/pages/collection/[id].tsx" /* webpackChunkName: "component---src-pages-collection-[id]-tsx" */),
  "component---src-pages-create-collection-tsx": () => import("./../../../src/pages/create_collection.tsx" /* webpackChunkName: "component---src-pages-create-collection-tsx" */),
  "component---src-pages-create-nft-tsx": () => import("./../../../src/pages/create_nft.tsx" /* webpackChunkName: "component---src-pages-create-nft-tsx" */),
  "component---src-pages-how-to-use-tsx": () => import("./../../../src/pages/how_to_use.tsx" /* webpackChunkName: "component---src-pages-how-to-use-tsx" */),
  "component---src-pages-how-to-use-video-tsx": () => import("./../../../src/pages/how_to_use_video.tsx" /* webpackChunkName: "component---src-pages-how-to-use-video-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-metamask-tsx": () => import("./../../../src/pages/metamask.tsx" /* webpackChunkName: "component---src-pages-metamask-tsx" */)
}

